import * as React from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import DisplayContentHeader from "./DisplayContentHeader";
export default function ServiceTable(services) {
  let columns,
    counter = [],
    columnSize = "",
    columnClass = "";

  if (services && Number.isInteger(services.columns)) {
    columns = services.columns;
  }

  if (services && services.services) {
    services = services.services;
  }

  if (columns !== 0) {
    columnSize = 12 / columns;
    columnClass = "is-" + columnSize;
  }
  let serviceCount = 0;

  if (services && services.length) {
    return (
      <React.Fragment key={nanoid()}>
        {services.map((service) => {
          serviceCount++;

          if (service.title) {
            return (
              <React.Fragment key={nanoid()}>
                {serviceCount > 1 ? <br /> : null}
                <DisplayContentHeader
                  title={service.title}
                  subtitle=""
                  summary=""
                  weight="bold"
                  showColon={false}
                  size={4}
                />
                {service.description ? <p>{service.description}</p> : null}
                <div className="p-4" key={nanoid()}>
                  <div className="service-table columns is-multiline">
                    {service.service.map((serviceDetail) => {
                      if (!counter[service.title]) {
                        counter[service.title] = 1;
                      } else {
                        counter[service.title]++;
                      }
                      return (
                        <div
                          className={`column color-${
                            counter[service.title]
                          } ${columnClass}`}
                          key={nanoid()}
                        >
                          <h3 className="title is-3 plan_title has-text-white has-text-weight-bold">
                            {serviceDetail.title}
                          </h3>

                          {serviceDetail.detail ? (
                            <p className="has-text-white is-3 has-text-weight-semibold plan_subtitle">
                              {serviceDetail.detail}
                            </p>
                          ) : null}

                          <div className="price">
                            <h4 className="title is-4 has-text-weight-semibold has-text-right">
                              {formatter.format(serviceDetail.price)}
                            </h4>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </React.Fragment>
            );
          } else {
            return <></>;
          }
        })}
      </React.Fragment>
    );
  }
}

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
ServiceTable.propTypes = {
  pricing: PropTypes.object,
};
